<template>
  <div class="container">

    <div class="container_block"  v-show="!isempty">
      <template v-for="(item,index) in announceList" :key="index">
        <div class="container_block_row" @click="toDeital(item.noticeId)" >
        <div class="container_block_left">
          <span><img
              src="../../assets/images/unreaded.png"
              alt=""
            ></span>
          <span>{{item.noticeTitle}}</span>
        </div>
        <div class="container_block_right">
          <span style="margin-right:10px">{{item.createTime}}</span>
          <span><img
              src="../../assets/images/right-arrow.png"
              alt=""
            ></span>
        </div>
      </div>
      <el-divider />
      </template>

    </div>
    <div
        class="container_empty"
        v-if="isempty"
    >
      <el-empty description="暂无数据" />
    </div>
  </div>
</template>
<script setup name="AnnounceList">
import { ref } from "vue";
import { useRouter } from "vue-router"
import { useStore } from 'vuex';
import {getMainStationInfo} from "@/api/station";
import {getNoticeList} from "@/api/home";

const query=ref({})
const router = useRouter();
//分站id
const stationId  = ref("");
const announceList = ref([])
const store = useStore();
const isempty = ref(false)
//初始化数据
const initData = async()=>{
  if( store.state.stationId != null){
    stationId.value = store.state.stationId;
  }else {
    let result = await getMainStationInfo();
    stationId.value = result.data.id;
  }
}
initData();
//获得公告列表
const getAnnounceList = async () => {
  query.value = {
    noticeType:2,
    stationId:stationId.value,
    pageNum: 1,
    pageSize: 3
  }
  let response = await getNoticeList(query.value);
  if(response?.code === 200){
    if (response.rows.length > 0) {
      announceList.value = response.rows;
      isempty.value = false;
    }else {
      isempty.value = true;
    }
  }else {
    isempty.value = true;
  }

}
getAnnounceList();
const toDeital = (id)=>{
  router.push({path: 'announceDetail',query: {
        id
      },})
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;
  &_block {
    margin: 0 auto;
    padding: 30px 30px 30px 30px;
    width: 62%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    &_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 18px;
      color: #000000;
    }
    &_left img {
      height: 24px;
      margin-right: 10px;
    }
    &_right {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      color: #505559;
    }
    &_right img {
      height: 24px;
      vertical-align: middle;
    }
  }
}
</style>
